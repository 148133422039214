<template>
  <AppTopNav></AppTopNav>
  <AppLayoutCommon>
    <div class="submit-suc">
      <div>
        <img :src="require('/static/icons/success.png')" alt="" />
        <span style="color: #606266; margin-top: 20px">订单提交成功</span>
      </div>

      <!-- <span style="margin-top: 10px">
        5s后自动跳转至会员中心, 或点击
        <RouterLink to="/">
          <a>手动跳转</a>
        </RouterLink>
      </span> -->
    </div>
    <div class="info-box">
      <div class="title">
        <!-- <span class="icon"></span> -->
        <span class="text">订单信息</span>
      </div>
      <dl>
        <dt>订单编号</dt>
        <dd>{{ tradeId }}</dd>
        <dt>供应商公司</dt>
        <dd>{{ supplier }}</dd>
      </dl>
      <dl>
        <dt>采购方</dt>
        <dd>{{ purchaser }}</dd>
      </dl>
    </div>

    <div class="alert">
      <div class="left">
        <Icon type="ios-bulb-outline"></Icon>
        <span>
          温馨提示：订单已生成并通知供应商，请您在-用户中心-我的提单
          中查看详情，及时确认订单并支付
        </span>
      </div>
      <div class="right">
        <Button class="btn" @click="toOrder()">前往我的提单</Button>
      </div>
    </div>
  </AppLayoutCommon>
</template>

<script>
import AppLayoutCommon from '@/components/AppLayoutCommon'

import AppTopNav from '@/components/AppTopNav'

export default {
  name: 'SubmitSuc',
  data () {
    return {
      purchaser: '', // 采购商
      supplier: '', // 供应商
      tradeId: '',
    }
  },
  setup () {
    return {}
  },
  created () {
    this.init()
  },
  components: {
    AppLayoutCommon,
    AppTopNav,
  },
  beforeRouteUpdate (to) {},
  methods: {
    init () {

      this.purchaser = this.$route.query.purchaser
      this.supplier = this.$route.query.supplier
      this.tradeId = this.$route.query.tradeId
    },
    toOrder () {
      this.$router.push({ path: '/user/purchase' })
    },
  },
}
</script>

<style lang="less">
.submit-suc {
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
}

a {
  color: #069;
}
.alert {
  height: 52px;
  width: 100%;
  display: flex;
  align-items: center;
  // justify-content: center;
  padding: 20px;
  background: #f4f4f5;
  color: #999999;
  margin-bottom: 10px;
  .left {
    justify-content: flex-start;
    display: flex;
    align-items: center;
  }
  .right {
    flex: 1;

    height: 50px;
    justify-content: flex-end;
    display: flex;
    align-items: center;
  }

  .btn {
    width: 82px;
    background: #d12b23;
    height: 36px;
    margin-left: 10px;
    font-weight: 400;
    font-size: 12px;
    color: #fff;
    text-align: center;
    line-height: 40px;
    border: 1px solid #cfcdcd;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
// 信息盒子
.info-box {
  border: 1px solid #dcdfe6;
  margin-top: 30px;
  padding: 20px;
  margin-bottom: 10px;
  dl {
    display: flex;
    line-height: 30px;
    dt {
      width: 80px;
      color: #999;
      font-size: 14px;
      i {
        display: inline-block;
        width: 0.5em;
      }
    }
    dd {
      flex: 1;
    }
  }
}
.title {
  height: 20px;
  line-height: 1;
  margin-bottom: 10px;
  padding: 25px 5px;
  .icon {
    display: inline-block;
    height: 20px;
    width: 5px;
    background-color: #d12b23;
    margin-right: 10px;
    vertical-align: middle;
  }
  .text {
    display: inline-block;
    vertical-align: middle;
    font-size: 16px;
    font-weight: 600;
  }
}
/deep/ .ivu-alert-close {
  right: 8px;
  top: 0px;
}
</style>
